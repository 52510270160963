var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overview-list"},[_c('v-divider'),_c('div',{staticClass:"content-area pa-8"},[_c('div',{staticClass:"text-h5 blue--text text--darken-4"},[_vm._v("ノート一覧")]),_c('v-row',{staticClass:"py-4"},[_c('v-col',{attrs:{"cols":"4"}}),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"フィルタ","single-line":"","hide-details":""},model:{value:(_vm.filterText),callback:function ($$v) {_vm.filterText=$$v},expression:"filterText"}})],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"4"}},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasWritePermission),expression:"hasWritePermission"}],attrs:{"color":"btAccent","dark":"","depressed":"","to":{ name: 'OverviewEdit' }}},[_c('v-icon',{staticClass:"pr-1"},[_vm._v("mdi-plus-circle-outline")]),_vm._v(" 新規登録 ")],1)],1)],1),_c('v-data-table',{staticClass:"data-table",staticStyle:{"background-color":"transparent"},attrs:{"headers":_vm.headers,"items":_vm.tableData,"search":_vm.filterText,"no-data-text":"1件も登録されていません。","no-results-text":"1件も登録されていません。","footer-props":{
        itemsPerPageText: '',
        itemsPerPageAllText: '全件',
        pageText: '{2}件中、{0}-{1}',
      }},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'OverviewEdit', query: { i: item.id } }}},[_c('span',[_vm._v(_vm._s(item.title))])])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.$router.push({ name: 'OverviewEdit', query: { i: item.id } })}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"disabled":!_vm.hasWritePermission},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]),_c('v-icon',{attrs:{"disabled":!_vm.hasWritePermission},on:{"click":function($event){return _vm.copyItem(item)}}},[_vm._v(" mdi-content-copy ")])]}}],null,true)})],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.infoDialog),callback:function ($$v) {_vm.infoDialog=$$v},expression:"infoDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-subtitle-2 d-flex justify-center"},[_vm._v("削除しますか？")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mx-2 mb-3",attrs:{"text":"","width":"190"},on:{"click":function($event){_vm.infoDialog = !_vm.infoDialog}}},[_vm._v("キャンセル ")]),_c('v-btn',{staticClass:"white--text mx-2 mb-3",attrs:{"color":"btAccent","width":"190","depressed":""},on:{"click":_vm.deleteOk}},[_vm._v("削除")]),_c('v-spacer')],1)],1)],1),_c('yes-no-dialog',{attrs:{"message":"複製して新しいノートを作成しますか？","yesText":"作成する","noText":"キャンセル","center":""},on:{"clickYes":function($event){return _vm.copyYes()},"clickNo":function($event){_vm.copyDialog = false}},model:{value:(_vm.copyDialog),callback:function ($$v) {_vm.copyDialog=$$v},expression:"copyDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }