<template>
  <div class="overview-list">
    <v-divider></v-divider>
    <div class="content-area pa-8">
      <div class="text-h5 blue--text text--darken-4">ノート一覧</div>

      <v-row class="py-4">
        <v-col cols="4"> </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="filterText"
            append-icon="mdi-magnify"
            label="フィルタ"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="4" class="d-flex justify-end">
          <v-btn
            color="btAccent"
            dark
            depressed
            :to="{ name: 'OverviewEdit' }"
            v-show="hasWritePermission"
          >
            <v-icon class="pr-1">mdi-plus-circle-outline</v-icon>
            新規登録
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        class="data-table"
        :headers="headers"
        :items="tableData"
        :search="filterText"
        style="background-color: transparent"
        no-data-text="1件も登録されていません。"
        no-results-text="1件も登録されていません。"
        :footer-props="{
          itemsPerPageText: '',
          itemsPerPageAllText: '全件',
          pageText: '{2}件中、{0}-{1}',
        }"
      >
        <template v-slot:[`item.title`]="{ item }">
          <router-link :to="{ name: 'OverviewEdit', query: { i: item.id } }">
            <span>{{ item.title }}</span>
          </router-link>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            class="mr-2"
            @click="$router.push({ name: 'OverviewEdit', query: { i: item.id } })"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            class="mr-2"
            :disabled="!hasWritePermission"
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
          <v-icon :disabled="!hasWritePermission" @click="copyItem(item)">
            mdi-content-copy
          </v-icon>
        </template>
      </v-data-table>
    </div>

    <v-dialog v-model="infoDialog" max-width="500px">
      <v-card>
        <v-card-title class="text-subtitle-2 d-flex justify-center">削除しますか？</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mx-2 mb-3"
            text
            width="190"
            @click="infoDialog = !infoDialog"
            >キャンセル
          </v-btn>
          <v-btn
            color="btAccent"
            class="white--text mx-2 mb-3"
            width="190"
            depressed
            @click="deleteOk"
            >削除</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 複製確認 -->
    <yes-no-dialog
      message="複製して新しいノートを作成しますか？"
      v-model="copyDialog"
      yesText="作成する"
      noText="キャンセル"
      center
      @clickYes="copyYes()"
      @clickNo="copyDialog = false"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { RepositoryFactory } from "../../../api/RepositoryFactory";
import UserEditDialog from "../../../components/atomic/organisms/master/UserEditDialog";
import YesNoDialog from "../atoms/YesNoDialog.vue";

export default {
  components: {
    UserEditDialog,
    YesNoDialog,
  },
  data() {
    return {
      headers: [
        { text: "タイトル", value: "title", width: "45%" },
        { text: "カテゴリー", value: "category", width: "20%" },
        { text: "最終更新", value: "updatedAt", width: "20%" },
        { text: "アクション", value: "actions", width: "15%", sortable: false },
      ],
      items: [],
      filterText: "",

      infoDialog: false,
      targetItem: null,
      copyDialog: false,
    };
  },
  computed: {
    ...mapGetters(["project", "userInfo", "hasWritePermission"]),

    tableData() {
      return this.items.map((item) => {
        return {
          id: item.overview.id,
          updatedAt: item.sheetInfo.updatedAt,
          title: item.overview.title,
          category: item.overview.category,
        };
      });
    },
  },
  async created() {
    await this.fetchOverviews();
  },
  methods: {
    async fetchOverviews() {
      // APIからデータを取得する
      const OverviewRepository = RepositoryFactory.get("overview");
      const response = await OverviewRepository.getList(this.project.id);
      if (response.data.result && response.data.resultCode === "0000") {
        this.items = response.data.data;
      } else {
        this._sendError(response.data.message);
      }
    },

    deleteItem(item) {
      this.infoDialog = true;
      this.targetItem = item;
    },

    async deleteOk() {
      if (this.targetItem) {
        const OverviewRepository = RepositoryFactory.get("overview");
        await OverviewRepository.delete(this.targetItem.id);
        this.infoDialog = false;
        await this.fetchOverviews();
        this.targetItem = null;
      }
    },

    copyItem(item) {
      this.copyDialog = true;
      this.targetItem = item;
    },

    async copyYes() {
      if (!this.targetItem) return;

      // 戦略情報(複製) APIを叩く
      const OverviewRepository = RepositoryFactory.get("overview");
      const response = await OverviewRepository.copy(
        this.targetItem.id,
        this.project.id,
        { userId: this.userInfo.userId }
      );

      // 複製したノートの編集ページへ移動する
      this.$router.push({
        name: "OverviewEdit",
        query: { i: response.data.data.overview.id },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.overview-list {
  background-color: #f2f8ff;
  height: calc(100vh - 125px);

  .content-area {
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;

    .data-table {
      & ::v-deep tbody {
        background-color: white;
      }
    }
  }
}
</style>
